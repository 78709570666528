<template lang='pug'>
.attachmentsEditor
    v-progress-circular(v-if='loading', color='brand', indeterminate)
    v-row.align-center(v-else, @drop.prevent='onDrop')
        v-col.flex-shrink-1.flex-grow-0
            v-chip-group
                v-chip.white--text(v-for='attachment in attachments', :key='attachment.id', close, color='brand', outlined, @click:close='confirmDelete(attachment)', @click='previewAttachment(attachment)')
                    template(v-slot:default)
                        span {{ attachment.filename }}
                        v-icon.ml-1(v-if='attachment.description', color='brand', title='This attachment has a note', small) note
                //- v-list-item-action
                //-     v-btn(color='red', icon)
                //-         v-icon delete
        span.font-italic(v-if='attachments.length === 0') No attachments added
        v-col.flex-grow-1.flex-shrink-0
            v-menu(:close-on-content-click='false', transition='scale-transition', offset-y, v-model='menu', max-width='500', min-width='300')
                template(v-slot:activator='{ on, attrs }', color='brand')
                    v-btn(icon, v-on='on', color='brand', title='Add Attachment')
                        v-icon add
                v-card(light)
                    v-card-title Upload New Attachment
                    v-card-text
                        v-file-input(clearable, label='Select File', @change='fileSelected', color='brand', outlined, dense, :loading='uploading', :disabled='uploading', v-model='selectedFile')
                    v-card-actions
                        v-spacer
                        v-btn.white--text(@click='menu = false', color='brand', :loading='uploading') Close
    v-dialog(v-model='deleteDialog', max-width='450')
        v-card(light)
            v-card-title Delete Attachment
            v-card-text
                p
                    span Are you sure you want to delete the
                    span.font-italic.brand--text {{ targetAttachment.filename }}
                    span  attachment?
                p You won't be able to recover the file after deleting it. You can choose to download the file before deleting it for safekeeping.
            v-card-actions
                v-spacer
                v-btn(text, @click='dismissDialog') Cancel
                v-btn.white--text(color='brand', @click='downloadAttachment') Download
                    v-icon file_download
                v-btn.white--text(color='red', @click='deleteAttachment', :loading='deletingAttachment') Delete
    v-dialog(v-model='previewDialog', max-width='70vw', scrollable)
        v-card(light)
            v-card-title Atachment Preview
            v-card-text
                v-row
                    v-col
                        v-textarea.mt-2(v-model='targetAttachment.description', @change='updateNoteDescription', label='Description/Notes', outlined, color='brand', :loading='savingNotes')
                template(v-if='fileIsPreviewable')
                    .text-h5 File Preview
                    span {{ targetAttachment.filename }}
                    v-row
                        v-col.text-center
                            template(v-if='targetType === "image"')
                                v-img.align-center(:src='previewSrc', @load='imageLoaded', :max-width='imageWidth', ref='previewImage')
                            template(v-if='targetType === "document"')
                                VuePdfEmbed(:source='previewSrc', v-if='previewSrc')
                            template(v-if='targetType === "text"')
                                embed(type='text/plain', :src='previewSrc', style='width: 100%')
                    v-row(v-if='loadingPreview')
                        v-col.text-center
                            v-progress-circular(color='brand', indeterminate)
                v-row(v-else)
                    v-col
                        span This file cannot be previewed. Download the file to view it.
            v-card-actions
                v-spacer
                v-btn.white--text(append-icon='file_download', color='brand', @click='downloadAttachment') Download
                v-btn(text, @click='dismissDialog') Close
</template>
<script>

import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';

export default {
    components: {
        VuePdfEmbed
    },
    props: {
        itemId: {
            type: String,
            default () {
                return '';
            }
        },
        tableName: {
            type: String,
            default () {
                return '';
            }
        }
    },
    data () {
        return {
            accessKey: 'satmmls',
            loading: true,
            uploading: false,
            menu: false,
            selectedFile: null,

            targetAttachment: {},

            deleteDialog: false,
            previewDialog: false,

            deletingAttachment: false,
            loadingPreview: false,
            previewSrc: '',
            previewableFiles: {
                document: ['pdf'],
                image: ['jpg', 'jpeg', 'png', 'gif'],
                text: ['txt', 'log']
            },
            imageWidth: 100,
            savingNotes: false,
            dragDropEvents: ['dragenter', 'dragover', 'dragleave', 'drop']
        };
    },
    methods: {
        async fileSelected (file) {
            if (!file) {
                return;
            }
            await this.uploadFile(file);
            this.selectedFile = null;
            await this.getAttachments();
            await this.$store.dispatch('mmls/attachmentsUpdated');
        },
        async uploadFile (file) {
            this.uploading = true;
            const requestData = new FormData();
            requestData.append('attachment', file);
            requestData.append('itemId', this.itemId);
            requestData.append('fileName', file.name);
            requestData.append('tableName', this.tableName);
            const results = await this.axios.post(
                'satapi/attachments/upload',
                requestData
            );
            this.menu = false;
            this.uploading = false;
        },
        async getAttachments () {
            this.loading = true;
            await this.$store.dispatch('mmls/getAttachments', this.itemId);
            this.loading = false;
        },
        confirmDelete (attachment) {
            this.targetAttachment = attachment;
            this.deleteDialog = true;
        },
        async previewAttachment (attachment) {
            this.targetAttachment = attachment;
            this.previewDialog = true;
        },
        /**
         * Downloads the target attachment
         */
        async downloadAttachment () {
            const extension = this.getFileExtension(this.targetAttachment.filename);
            const filename = `${this.targetAttachment.id}.${extension}`;
            const response = await this.axios({
                method: 'get',
                url: '/satapi/downloadAttachment',
                params: {file: filename},
                responseType: 'arraybuffer'
            });
            const fileData = new File([response.data], {type: response.headers['content-type']});
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(fileData);
            link.download = this.targetAttachment.filename;
            document.body.appendChild(link);
            link.click();
        },
        dismissDialog () {
            this.targetAttachment = {};
            this.previewDialog = false;
            this.deleteDialog = false;
        },
        async deleteAttachment () {
            this.deletingAttachment = true;
            await this.sendCommand({
                action: 'deleteAttachment',
                parameters: {
                    id: this.targetAttachment.id
                }
            });
            this.dismissDialog();
            this.$store.dispatch('mmls/attachmentsUpdated');
            this.deletingAttachment = false;
        },
        getFileExtension (filename) {
            const parts = filename.split('.');
            if (parts.length > 1) {
                return parts[parts.length -1];
            } else {
                return null;
            }
        },
        async getPreviewSrc (attachment) {
            const extension = this.getFileExtension(attachment.filename);
            const response = await this.axios({
                method: 'get',
                url: `/satapi/filepreview/${attachment.id}.${extension}`,
                responseType: 'arraybuffer'
            });
            const mimeType = response.headers['content-type'].toLowerCase();
            const base64Data = new Buffer(response.data, 'binary').toString('base64');
            return `data:${mimeType};base64, ${base64Data}`;
        },
        imageLoaded () {
            this.imageWidth = this.$refs.previewImage.naturalWidth;
        },
        async updateNoteDescription () {
            this.savingNotes = true;
            await this.sendCommand({
                action: 'updateAttachment',
                parameters: {
                    attachment: this.targetAttachment
                }
            });
            this.savingNotes = false;
        },
        onDrop (event) {
            this.selectedFile = event.dataTransfer.files[0];
            this.fileSelected(this.selectedFile);
        }
    },
    computed: {
        attachments () {
            return this.$store.getters['mmls/attachments'];
        },
        fileIsPreviewable () {
            return Boolean(this.targetType);
        },
        targetType () {
            let targetType = null;
            if (this.targetAttachment && this.targetAttachment.filename) {
                const extension = this.getFileExtension(this.targetAttachment.filename);
                for (let fileType of Object.keys(this.previewableFiles)) {
                    if (this.previewableFiles[fileType].includes(extension)) {
                        targetType = fileType;
                        break;
                    }
                }
            }
            return targetType;
        },
        targetFileName () {
            return this.targetAttachment.filename;
        }
    },
    watch: {
        async targetFileName (newFileName) {
            if (this.fileIsPreviewable && newFileName) {
                this.loadingPreview = true;
                const src = await this.getPreviewSrc(this.targetAttachment);
                this.previewSrc = src;
                this.loadingPreview = false;
            } else {
                this.previewSrc = null;
            }
        },
        itemId: {
            immediate: true,
            handler (itemId) {
                this.loading = true;
                if (itemId) {
                    this.getAttachments();
                }
            }
        }
    },
    mounted () {
        function preventDefaults (e) {
            e.preventDefault();
        }
        this.dragDropEvents.forEach(eventName => {
            document.body.addEventListener(eventName, preventDefaults);
        });
    },
    unmounted () {
        this.dragDropEvents.forEach(eventName => {
            document.body.removeEventListener(eventName);
        });
    }
};
</script>
